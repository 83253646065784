import { Column } from 'react-table';
import { ComponentProps } from 'shared/definition';
import { useActionRowTableProps } from '../../hooks/useActionRow';
import { Justify, Selection } from './components/definition';
import { HasPagination, HasPaginationPadding } from './components/Pagination';
import { TableType } from './style/definition';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type Cell<T> = { value: T };

export type DragIndex = {
  /** New row index */
  new: number;
  /** Old row index */
  old: number;
};

interface Props extends ComponentProps {
  /** Does the table border have a radius? */
  hasBorderRadius?: boolean;
  /** Do the table cells have padding? */
  hasCellPadding?: boolean;
  /** Does the table have an outer border? */
  hasOuterBorder?: boolean;
  /** Is the Pagination bar visible? */
  hasPagination?: HasPagination;
  /** Does the Pagination bar have padding? */
  hasPaginationPadding?: HasPaginationPadding;
  /** Does the table have margin on either side? */
  hasSideMargin?: boolean;
  /** Are the body rows scrollable? */
  isScrollable?: boolean;
  /** Row selection object (position, type, handler) */
  selection?: Selection;
  /** Type of table */
  type?: TableType;
}

export interface TableProps extends Props, useActionRowTableProps {
  /** Is the Action Row present? */
  hasActionRow?: boolean;
  /** Does the Action Row have a (bottom) border? */
  hasActionRowBorder?: boolean;
  /** Does the Action Row have a breadcrumb? */
  hasActionRowBreadcrumb?: boolean;
  /** Does the Action Row have a default (right) button group? */
  hasActionRowButtonGroup?: boolean;
  /** Does the Action Row have (bottom) margin */
  hasActionRowMargin?: boolean;
  /** Does the Action Row have padding? */
  hasActionRowPadding?: {
    top?: boolean;
    side?: boolean;
    bottom?: boolean;
  };
  /** Does the page index auto-reset when data is updated? */
  hasAutoResetPage?: boolean;
  /** Is the first data column auto-sorted? */
  hasAutoSort?: boolean;
  /** Is the drag icon visible? */
  hasDragIcon?: boolean;
  /** Is the edit icon visible? */
  hasEditIcon?: boolean;
  /** Are the column headings visible? */
  hasHeadings?: boolean;
  /** Is the Pagination bar visible? */
  hasPagination?: HasPagination;
  /** Does the Pagination bar have padding? */
  hasPaginationPadding?: HasPaginationPadding;
  /** Is filtering applied to subrows? */
  hasSubRowFilter?: boolean;
  /** Custom Action Row buttons */
  actionRowButtons?: Record<string, JSX.Element>;
  /** Custom Action Row button alignment */
  actionRowButtonAlignment?: Record<string, Justify>;
  /** Table columns */
  columns: Array<Column>;
  /** Table data */
  data: any;
  /** Custom data-test-id value */
  dataTestId?: string;
  /** Width of draggable row, when enabled */
  dragWidth?: number;
  /** Custom text for no items found */
  noItemsFoundText?: string;
  /** Custom 'No items found' message */
  noItemsMessage?: JSX.Element | string;
  /** Custom page size (number of rows to display) */
  pageSize?: number;
  /** Custom pagination info text (i18n key) */
  paginationInfoText?: string;
  /** Table title */
  title?: string | JSX.Element;
  /** handleDragEnd event handler */
  handleDragEnd?: (index: DragIndex) => void;

  /**
   * Disables sorting for every column in the entire table.
   */
  disableSortBy?: boolean;
  /**
   * • The initial state object for the table.
   * • Upon table initialization, this object is merged over the table's defaultState object (eg. {...defaultState, ...initialState})
   *   that React Table and its hooks use to register default state to produce the final initial state object passed to the
   *   React.useState hook internally.
   */
  initialState?: any;
  /**
   * • Enables pagination functionality, but does not automatically perform row pagination.
   * • Turn this on if you wish to implement your own pagination outside of the table
   *   (eg. server-side pagination or any other manual pagination technique)
   */
  manualPagination?: boolean;
  /**
   * • Enables sorting detection functionality, but does not automatically perform row sorting.
   * • Turn this on if you wish to implement your own sorting outside of the table (eg. server-side or manual row grouping/nesting)
   */
  manualSortBy?: boolean;
  /**
   * • If set to true, expanded rows are paginated along with normal rows. This results in stable page sizes across every page.
   * • If set to false, expanded rows will be spliced in after pagination. This means that the total number of rows in a page can
   *   potentially be larger than the page size, depending on how many subrows are expanded.
   */
  paginateExpandedRows?: boolean;
  /** The render function to render no items message view */
  renderNoItemsFooter?: () => JSX.Element;
  renderTablePostfix?: (keyword: string) => JSX.Element;
}

export interface StyledTableProps extends Props {
  /** Is the (removable) table action icon visible? */
  hasActionIcon?: boolean;
  /** Is the table footer visible? */
  hasFooter?: boolean;
  /** Theme object */
  theme: Record<string, any>;
}
