import { ADD_KEY } from 'constant';
import cloneDeep from 'lodash/cloneDeep';
import { useFetchAccountDescendantsByIdQuery } from 'models/Account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { columns } from './utility';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Array<unknown>>([]);
  const [isTableDataPrepared, setIsTableDataPrepared] = useState<boolean>(true);

  const { data, isLoading } = useFetchAccountDescendantsByIdQuery();
  // const { data: childData, isLoading } = useFetchAccountChildrenByIdQuery(); // leaving this here for now (see below)

  useEffect(() => {
    if (data) {
      // record the account depth (to determine which depth to display)
      let depth: Array<number> = [];

      // build account object, in order to nest child accounts
      const accountObject = data.reduce((acc: any, account: any): any => {
        const accountDepth = account.tree.length - 1;
        depth.push(accountDepth); // save depth
        acc[account.id] = {
          ...cloneDeep(account),
          /**
           * subRows: Assign empty subRows array
           * depth:   Assign depth (based on tree length)
           * tree:    Discard uppermost ascendant and reassign tree
           */
          subRows: [],
          depth: accountDepth,
          tree: [...account.tree.slice(1)],
        };
        return acc;
      }, {});

      // remove duplicate depth(s) and sort array
      depth = [...new Set(depth)].sort();

      const accountArray = Object.values(accountObject);
      accountArray.forEach((account: any): void => {
        /**
         * If this account has ascendants, pop (remove) the last ascendant
         * account on the tree and save the current (child) account to the
         * ascendant's subRows array
         */
        if (account.tree.length > 0) {
          accountObject[account.tree.pop()]?.subRows.push(account);
        }
      });

      setTableData(accountArray.filter((acc: any): boolean => acc.depth === depth[0]));
    }

    setIsTableDataPrepared(false);
  }, [data]);

  /**
   * Leaving lazy loading of children/subRows here for now
   * as we may revisit this in the future
   *
   * useEffect(() => {
   * 	 childData && setData(childData);
   * }, [childData]);
   */

  if (isLoading || isTableDataPrepared) {
    return <Loading hasLargeProgress />;
  }

  return (
    <Table
      hasSearch
      hasSubRowFilter
      addButton={{
        hasAdd: true,
        label: t('accounts_manager:component.table.action_row.button.add_new_account.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      columns={columns()}
      data={tableData}
      dataTestId={TEST_ID.ACCOUNTS_MANAGER.LIST.CONTAINER}
      hasCellPadding={false}
      hasEditIcon={false}
      hasPagination={{
        bar: false,
      }}
      initialState={{
        manualPagination: true,
        paginateExpandedRows: false,
      }}
      // t('accounts_manager:containers.accounts.table.pagination.pagination_info.text')
      paginationInfoText="accounts_manager:containers.accounts.table.pagination.pagination_info.text"
      title={t('accounts_manager:containers.accounts.table.title')}
    />
  );
};

export default List;
