import { ComponentProps } from 'shared/definition';

export enum ErrorType {
  Default,
  Iframe,
}

interface Props extends ComponentProps {
  /** Error type */
  type?: ErrorType;
}

export interface ErrorProps extends Props {
  /** Error message */
  message?: string | JSX.Element;
}

export type StyledErrorProps = Props;
